import { NavigationSecondary } from 'presentation/ui/components/navigation/navigation-secondary/NavigationSecondary';
import { MainLayoutSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSection';
import { MainLayoutSectionSection } from 'presentation/ui/layouts/main-layout/main-layout-section/MainLayoutSectionSection';
import { MainLayout } from 'presentation/ui/layouts/main-layout/MainLayout';
import { TopbarLayoutSection } from 'presentation/ui/layouts/main-layout/topbar-layout-section/TopbarLayoutSection';
import { ViewLayoutSection } from 'presentation/ui/layouts/view-layout/view-layout-section/ViewLayoutSection';
import { ViewLayout } from 'presentation/ui/layouts/view-layout/ViewLayout';
import { UserProfile } from 'presentation/ui/partials/navigation/navigation-secondary/UserProfile/UserProfile';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'services/core/context/AuthContext';
import { FacilityContext } from 'services/core/context/FacilityContext';
import { Route } from 'router/Route';
import { Permission, permissionGroupCyclotronProduct } from 'services/core/lib/auth/AuthService';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ButtonSecondary, ButtonSecondaryStatus } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { L10nContext } from 'context/L10nContext';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Footer } from 'presentation/ui/compositions/footer/Footer';
import { CyclotronProductModelState } from 'services/nuclide/domain/model/CyclotronProductModelState';

import { useHistory, useParams } from 'react-router-dom';
import {
	selectCyclotronProductByUuid,
	resetActionStatus as resetCyclotronProductActionStatus
} from 'services/nuclide/store/cyclotronProductSlice';
import { useTypedSelector } from 'store/common/TypedSelector';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncReducerStatus } from 'store/common/AsyncReducerStatus';
import { LoadingSpinner } from 'presentation/ui/components/loading-spinner/LoadingSpinner';
import { Breadcrumbs } from 'presentation/ui/partials/navigation/navigation-secondary/Breadcrumbs/Breadcrumbs';
import { CyclotronProductIsotope } from 'services/nuclide/domain/model/isotopes/CyclotronProductIsotope';
import { CyclotronProductCard } from '../ui/cyclotron-product-card/CyclotronProductCard';
import { CyclotronProductViewModalType } from './CyclotronProductViewModalType';
import { CyclotronProductDeleteModal } from '../ui/cyclotron-product-action/cyclotron-product-delete-modal/CyclotronProductDeleteModal';
import { CyclotronProductUpdateModal } from '../ui/cyclotron-product-action/cyclotron-product-update-modal/CyclotronProductUpdateModal';
import { CyclotronProductCompletelyAppliedModal } from '../ui/cyclotron-product-action/cyclotron-product-completely-applied-modal/CyclotronProductCompletelyAppliedModal';
import { CyclotronProductUndoCompletelyAppliedModal } from '../ui/cyclotron-product-action/cyclotron-product-undo-completely-applied-modal/CyclotronProductUndoCompletelyAppliedModal';
import { CyclotronProductToWasteModuleModal } from '../ui/cyclotron-product-action/cyclotron-product-to-waste-module-modal/CyclotronProductToWasteModuleModal';

interface CyclotronProductViewParams {
	cyclotronProductUuid: string;
}

export const CyclotronProductView = (): JSX.Element => {

	// // Unwrap the required cyclotron product uuid from the route
	const params = useParams<CyclotronProductViewParams>();
	const cyclotronProductUuid = params?.cyclotronProductUuid ?? null;
	if (cyclotronProductUuid === null) {
		throw new Error('Cyclotrone product id is missing');
	}

	// Read the cyclotron product from the state store
	const cyclotronProduct = useSelector(selectCyclotronProductByUuid(cyclotronProductUuid));

	// Consume the contexts
	const authContext = useContext(AuthContext);
	const facilityContext = useContext(FacilityContext);
	const l10nContext = useContext(L10nContext);

	// Consume the history object
	const history = useHistory();

	// Handle permissions
	if (!authContext.hasAnyPermission(permissionGroupCyclotronProduct)) {
		throw new Error('Permission denied');
	}

	// Local state
	const [modalType, setModalType] = useState<CyclotronProductViewModalType>(null);

	// Use a custom selector function to provide type information for the state defined in the slice
	const cyclotronProductsActionStatus = useTypedSelector(state => state.cyclotronProducts.actionStatus);

	// Consume the dispatch object
	const dispatch = useDispatch();

	// Handle close modal for success and failure
	const handleDismiss = () => {
		setModalType(null);
	};

	useEffect(() => {
		if (cyclotronProductsActionStatus === AsyncReducerStatus.DELETED) {
			dispatch(resetCyclotronProductActionStatus());
			history.replace(Route.NUCLIDE_MANAGEMENT + Route.CYCLOTRON_PRODUCTS);
		}
	}, [cyclotronProduct, cyclotronProductsActionStatus, dispatch, history]);

	// Show up a loading indicator while the cyclotron product fetching is pending
	if (
		cyclotronProductsActionStatus === AsyncReducerStatus.DELETE_PENDING
		|| cyclotronProductsActionStatus === AsyncReducerStatus.DELETED
	) {
		return (
			<LoadingSpinner />
		);
	}

	if (cyclotronProduct === null ) {
		// Show up an error if the cyclotron product is not available
		throw new Error('Cyclotron product not found');
	}

	// Provide user data
	const user = {
		name: authContext.getActor().Realname,
		location: facilityContext.selectedFacility().Name,
		permissionUpdate: authContext.hasPermission(Permission.CYCLOTRON_PRODUCT_UPDATE),
		permissionDelete: authContext.hasPermission(Permission.CYCLOTRON_PRODUCT_DELETE)
	};

	const handleModalClick = (type: CyclotronProductViewModalType) => {
		dispatch(resetCyclotronProductActionStatus());

		setModalType(type);
	};

	// Show modal
	const showModal = (): JSX.Element => {
		let modal: JSX.Element = null;

		switch (modalType) {
			case CyclotronProductViewModalType.EDIT:
				modal =
					<CyclotronProductUpdateModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.DELETE:
				modal =
					<CyclotronProductDeleteModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.COMPLETELY_APPLIED:
				modal =
					<CyclotronProductCompletelyAppliedModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.UNDO_COMPLETELY_APPLIED:
				modal =
					<CyclotronProductUndoCompletelyAppliedModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;

			case CyclotronProductViewModalType.TO_WASTE_MODULE:
				modal =
					<CyclotronProductToWasteModuleModal
						cyclotronProduct={cyclotronProduct}
						onDismiss={handleDismiss}
					/>;
				break;
		}
		return modal;
	};

	const cyclotronProductCard =
		<CyclotronProductCard
			cyclotronProduct={cyclotronProduct}
			userPermissionEdit={user.permissionUpdate}
			onClick={() => handleModalClick(CyclotronProductViewModalType.EDIT)}
		/>;

	const toWasteManagementButton = cyclotronProduct.State === CyclotronProductModelState.ACTIVE ?
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.toWasteModule', 'An Abfallmodul')}
			icon={IconIdentifier.DISPATCH}
			onClick={() => handleModalClick(CyclotronProductViewModalType.TO_WASTE_MODULE)}
			disabled={!user.permissionUpdate}
		/> :
		null;

	const appliedButton =
	cyclotronProduct.Isotope === CyclotronProductIsotope.IODINE_131_I ?
		<ButtonSecondary
			buttonText={
				cyclotronProduct.State !== CyclotronProductModelState.COMPLETELY_APPLIED ?
					l10nContext.translate('common.button.completelyApplied', 'Vollständig applizieren') :
					l10nContext.translate('common.button.undoCompletelyApplied', 'Vollständig appliziert aufheben')
			}
			icon={IconIdentifier.FULLY_APPLIED}
			onClick={() => {
				cyclotronProduct.State !== CyclotronProductModelState.COMPLETELY_APPLIED ?
					handleModalClick(CyclotronProductViewModalType.COMPLETELY_APPLIED) :
					handleModalClick(CyclotronProductViewModalType.UNDO_COMPLETELY_APPLIED);
			}}
			disabled={!user.permissionUpdate}
		/> :
		null;

	const deleteButton =
		<ButtonSecondary
			buttonText={l10nContext.translate('common.button.delete', 'Löschen')}
			icon={IconIdentifier.TRASH}
			status={ButtonSecondaryStatus.ATTENTION}
			disabled={!user.permissionDelete}
			onClick={() => handleModalClick(CyclotronProductViewModalType.DELETE)}
		/>;

	return (
		<MainLayout>
			<TopbarLayoutSection>
				<NavigationSecondary>
					<Breadcrumbs
						mapURLFragments={[[cyclotronProductUuid, cyclotronProduct.Name]]}
					/>
					<UserProfile
						userName={user.name}
						location={user.location}
					/>
				</NavigationSecondary>
			</TopbarLayoutSection>

			<MainLayoutSection
				section={MainLayoutSectionSection.SECTION_MAIN}
			>
				<ViewLayout>

					<ViewLayoutSection>
						{showModal()}
						{cyclotronProductCard}
					</ViewLayoutSection>

					<ViewLayoutSection>
						<ColumnLayout>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{toWasteManagementButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
								{appliedButton}
							</ColumnLayoutSection>
							<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
								{deleteButton}
							</ColumnLayoutSection>
						</ColumnLayout>
					</ViewLayoutSection>
				</ViewLayout>
			</MainLayoutSection>

			<MainLayoutSection section={MainLayoutSectionSection.SECTION_FOOTER}>
				<Footer />
			</MainLayoutSection>

		</MainLayout>
	);
};
