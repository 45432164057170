import { ModelConverter as BaseModelConverter } from 'lib/domain/model/ModelConverter';
import { HttpPersistence } from 'lib/persistence/http/HttpPersistence';

import {
	CreatableDocumentViewModel as CreatableViewModel,
	DocumentModelConverter as ModelConverter,
	DocumentPersistenceModel as PersistenceModel,
	DocumentStoreModel as StoreModel,
	DocumentViewModel as ViewModel
} from 'services/documents/domain/model/DocumentModel';

export class DocumentHttpPersistence extends HttpPersistence<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> {
	protected serviceApiControllerUri = 'document/api/v1';
	protected collectionControllerUri = 'documents';
	protected entityControllerUri = 'document';

	protected responseCollectionKey = 'DocumentModelCollection';
	protected responseEntityKey = 'DocumentModel';

	protected modelConverter: BaseModelConverter<PersistenceModel, StoreModel, ViewModel, CreatableViewModel> = new ModelConverter();

	async fetchCollectionByMaintenanceLogEntry(maintenanceLogUuid: string): Promise<Array<StoreModel>> {

		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + '/documents/maintenance-log-entry/' + maintenanceLogUuid;
		});
	}

	async fetchCollectionByDevice(deviceUuid: string): Promise<Array<StoreModel>> {

		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + '/documents/device/' + deviceUuid;
		});
	}

	async fetchCollectionByRecord(recordUuid: string): Promise<Array<StoreModel>> {

		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + '/documents/record/' + recordUuid;
		});
	}

	async fetchCollectionByFolder(documentFolderUuid: string): Promise<Array<StoreModel>> {

		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + '/documents/document-folder/' + documentFolderUuid;
		});
	}

	async fetchCollectionByFacility(): Promise<Array<StoreModel>> {

		return super.fetchCollection(() => {
			return process.env.REACT_APP_API_BASE_URL + this.serviceApiControllerUri + '/'
				+ this.getClientUuid() + '/' + this.getFacilityUuid() + '/documents/facility';
		});
	}

	async create(storeModel: StoreModel): Promise<StoreModel> {
		if (storeModel?.File instanceof File) {
			storeModel.File = {
				Filename: storeModel.File.name,
				Filedata: await this.convertFile(storeModel.File)
			};
		}
		return super.create(storeModel);
	}

	private async convertFile(file: File): Promise<string | null> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = error => reject(error);
			reader.readAsDataURL(file);
		});
	}

}
