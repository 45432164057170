import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';

import { ColumnLayoutSection } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSection';
import { ColumnLayoutSectionMode } from 'presentation/ui/layouts/column-layout/column-layout-section/ColumnLayoutSectionMode';
import { ColumnLayout } from 'presentation/ui/layouts/column-layout/ColumnLayout';
import { FormLayoutHeader } from 'presentation/ui/layouts/form-layout/form-layout-header/FormLayoutHeader';
import { FormLayoutSection } from 'presentation/ui/layouts/form-layout/form-layout-section/FormLayoutSection';
import { FormLayout } from 'presentation/ui/layouts/form-layout/FormLayout';
import { FormLayoutModifier } from 'presentation/ui/layouts/form-layout/FormLayoutModifier';
import { FormLayoutSectionAlign } from 'presentation/ui/layouts/form-layout/FormLayoutSectionAlign';
import { ButtonPrimary } from 'presentation/ui/partials/button/button-primary/ButtonPrimary';
import { ButtonSecondary } from 'presentation/ui/partials/button/button-secondary/ButtonSecondary';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { Label } from 'presentation/ui/partials/input/label/Label';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';
import { InputText, InputTextStatus } from 'presentation/ui/partials/input/input-text/InputText';

export interface GeneratorDispatchFormStep2Props {
	/**
	 * Generator
	 */
	generator: GeneratorViewModel;
	/**
	 * Button back action
	 */
	onClickPrevious: () => void;
	/**
	 * Button confirm
	 */
	onClickConfirm?: () => void;
}

/**
 * The generator dispatch modal step 2 component
 */
export const GeneratorDispatchFormStep2 = (props: GeneratorDispatchFormStep2Props): JSX.Element => {
	const { generator, onClickPrevious, onClickConfirm } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const dispatchDatetimeFormatted = l10nContext.formatDateTime(generator.DispatchDatetime);

	return (
		<FormLayout
			align={FormLayoutSectionAlign.ALIGN_HORIZONTAL}
			modifier={FormLayoutModifier.BLUE_BACKGROUND}
			onSubmit={onClickConfirm}
		>
			<FormLayoutHeader
				text={l10nContext.translate('common.modal.checkDetails', 'Überprüfen Sie bitte Ihre Angaben')}
				icon={IconIdentifier.WARNING}
			/>

			<FormLayoutSection>
				<Label label={`${l10nContext.translate('generators.dispatchDate', 'Datum Abgang')}*`}>
					<InputText
						type='text'
						value={dispatchDatetimeFormatted}
						status={InputTextStatus.SUMMARY}
						disabled
					/>
				</Label>
			</FormLayoutSection>

			<FormLayoutSection align={FormLayoutSectionAlign.ALIGN_BOTTOM}>
				<ColumnLayout>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FLEX}>
						<ButtonSecondary
							buttonText={l10nContext.translate('common.button.back', 'Zurück')}
							onClick={onClickPrevious}
						/>
					</ColumnLayoutSection>
					<ColumnLayoutSection mode={ColumnLayoutSectionMode.MODE_FIXED}>
						<ButtonPrimary
							buttonText={l10nContext.translate('view.generators.modal.action.dispatch.caption', 'Abgang erfassen')}
							type="submit"
						/>
					</ColumnLayoutSection>
				</ColumnLayout>
			</FormLayoutSection>
		</FormLayout>
	);
};
