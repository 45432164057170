import { memo, useContext } from 'react';
import { L10nContext } from 'context/L10nContext';
import { Guid } from 'lib/guid/Guid';
import { GeneratorViewModel } from 'services/nuclide/domain/model/GeneratorModel';

import { getGeneratorMeasurementUnitDefinition } from '../../../../../../services/nuclide/domain/model/NuclideMeasurementUnitDefinition';

import './card-item-generator-dispatch-attributes.scss';

export interface CardItemGeneratorDispatchAttributesProps {
	/**
	 * Generator
	 */
	generator: GeneratorViewModel;
}

/**
 * A card item generator depatch attributes component
 */
export const CardItemGeneratorDispatchAttributes = memo((props: CardItemGeneratorDispatchAttributesProps): JSX.Element => {
	const { generator } = props;

	// Consume the context
	const l10nContext = useContext(L10nContext);

	const dispatchDatetimeFormatted = l10nContext.formatDateTime(generator.DispatchDatetime);
	const activityUnit = l10nContext.translate(getGeneratorMeasurementUnitDefinition(generator.ActivityUnit).getLabelKey());

	const checkEmptyValue = (val: string): string => {
		return val !== '' && val !== null ? val : '-';
	};

	const showDispatchedActivity = generator.DispatchedActivity !== null ? l10nContext.formatNumber(generator.DispatchedActivity) + ' ' + activityUnit : '-';

	const translations = {

		dispatchDate: { label: l10nContext.translate('generators.dispatchDate', 'Datum Abgang'), value: checkEmptyValue(dispatchDatetimeFormatted) },
		dispatchedByName: { label: l10nContext.translate('generators.dispatchRecordedBy', 'Abgang erfasst durch'), value: checkEmptyValue(generator.DispatchedByName) },
		dispatchedActivity: { label: l10nContext.translate('generators.dispatchedActivity', 'Aktivität bei Abgang'), value: showDispatchedActivity },
	};

	const attributes = () => {
		return Object.entries(translations).map((translation) => {
			const { label, value } = translation[1];

			const styleClass = 'attribute';

			return (
				<label
					key={Guid.generate()}
					className={styleClass}
				>
					<strong>{label}:&nbsp;</strong>
					<p>{ value ?? '-' }</p>
				</label>
			);
		});
	};

	return (
		<div className='card-item-generator-dispatch-attributes'>
			<ul className='card-item-generator-dispatch-attributes__attribute'>
				{attributes()}
			</ul>
		</div>
	);
});
