import { CreatableViewModel, PersistenceModel, StoreModel, ViewModel } from 'lib/domain/model/Model';
import { ModelConverter } from 'lib/domain/model/ModelConverter';

export interface ContainerDispatchPersistenceModel extends PersistenceModel {
	Client: string,
	Facility: string,
	Container: string,
	DispatchedDatetime: number,
	DispatchedBy: string,
	DispatchedByName: string,
	DispatchedActivity: number,
	DispatchedComment: string
}

export interface ContainerDispatchStoreModel extends StoreModel {
	Client: string,
	Facility: string,
	Container: string,
	DispatchedDatetime: number,
	DispatchedBy: string,
	DispatchedByName: string,
	DispatchedActivity: number,
	DispatchedComment: string
}

export interface ContainerDispatchViewModel extends ViewModel {
	Client: string,
	Facility: string,
	Container: string,
	DispatchedDatetime: Date,
	DispatchedBy: string,
	DispatchedByName: string,
	DispatchedActivity: number,
	DispatchedComment: string
}

export interface CreatableContainerDispatchViewModel extends CreatableViewModel {
	Client: string,
	Facility: string,
	Container: string,
	DispatchedDatetime: Date,
	DispatchedBy: string,
	DispatchedByName: string,
	DispatchedActivity: number,
	DispatchedComment: string
}

export class ContainerDispatchModelConverter
	extends ModelConverter<ContainerDispatchPersistenceModel, ContainerDispatchStoreModel, ContainerDispatchViewModel, CreatableContainerDispatchViewModel> {

	fromStoreModel(storeModel?: ContainerDispatchStoreModel): this {
		return super.fromStoreModel(storeModel, (sModel, model) => {
			model.DispatchedDatetime = Math.floor(sModel.DispatchedDatetime / 1000);
		});
	}

	fromViewModel(viewModel?: ContainerDispatchViewModel): this {
		return super.fromViewModel(viewModel, (vModel, model) => {
			model.DispatchedDatetime = Math.floor(vModel.DispatchedDatetime.getTime() / 1000);
		});
	}

	toViewModel(): ContainerDispatchViewModel | null {
		return super.toViewModel((model, vModel) => {
			vModel.DispatchedDatetime = new Date(model.DispatchedDatetime * 1000);
		});
	}

	toStoreModel(): ContainerDispatchStoreModel | null {
		return super.toStoreModel((model, sModel) => {
			sModel.DispatchedDatetime = model.DispatchedDatetime * 1000;
		});
	}

}
