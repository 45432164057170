import { useContext } from 'react';

import { L10nContext } from 'context/L10nContext';
import { NetworkContext } from 'context/NetworkContext';

import { Guid } from 'lib/guid/Guid';

import { AuthContext } from 'services/core/context/AuthContext';
import { Permission } from 'services/core/lib/auth/AuthService';
import { DocumentViewModel } from 'services/documents/domain/model/DocumentModel';
import { DocumentModelScope } from 'services/documents/domain/model/DocumentModelScope';
import { DocumentModelState } from 'services/documents/domain/model/DocumentModelState';

import { CardDocument } from 'presentation/ui/components/cards/card-document/card-document/CardDocument';
import { CardItemControls } from 'presentation/ui/partials/card/card-item-controls/card-item-controls/CardItemControls';
import { CardItemSimpleControls } from 'presentation/ui/partials/card/card-item-controls/card-item-simple-control/CardItemSimpleControls';
import { CardItemActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemActionTypes';
import { CardItemControlState } from 'presentation/ui/partials/card/card-item-controls/CardItemControlState';
import { CardItemControlActionType } from 'presentation/ui/partials/card/card-item-controls/CardItemControlType';
import { CardItemFileSize } from 'presentation/ui/partials/card/card-item-file-size/CardItemFileSize';
import { CardItemHeader } from 'presentation/ui/partials/card/card-item-header/card-item-header/CardItemHeader';
import { CardItemState } from 'presentation/ui/partials/card/card-item-state/CardItemState';
import { IconIdentifier } from 'presentation/ui/partials/icon/IconIdentifier';
import { IllustrationIdentifier } from 'presentation/ui/partials/illustration/IllustrationIdentifier';

import 'presentation/ui/components/cards/card-maintenance-log/card-maintenance-log-list/card-maintenance-log-list.scss';

export interface DocumentEntryProps {
	isReportView?: boolean,
	documentEntry: DocumentViewModel,
	onActionClick?: (actionPayload: CardItemControlActionType, documentEntry: DocumentViewModel) => void;
}

export const DocumentEntry = (props: DocumentEntryProps): JSX.Element => {
	const { documentEntry, isReportView, onActionClick } = props;

	const authContext = useContext(AuthContext);
	const l10nContext = useContext(L10nContext);
	const networkContext = useContext(NetworkContext);

	const onCardItemControlClick = (action: CardItemControlActionType, document: DocumentViewModel) => {
		action.type === CardItemActionType.ARCHIVE && documentEntry.State === DocumentModelState.ARCHIVED ?
			onActionClick({
				...action,
				type: CardItemActionType.UNARCHIVE
			}, document) :
			onActionClick(action, document);
	};

	const formatBytes = (number: number): string => {
		const k = 1024;
		const dm = 0;
		const sizes = ['Bytes', 'KB', 'MB', 'GB'];
		const i = Math.floor(Math.log(number) / Math.log(k));

		return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	const formatedDocumentDate = l10nContext.formatDateTime(documentEntry.CreatedAt);
	const formatedFileExtension = documentEntry.Filename.split('.').pop().toUpperCase() + ' (' + formatBytes(documentEntry.FileInfo.Filesize) + ')';

	const controls = () => {
		if (documentEntry.Scope === DocumentModelScope.MAINTENANCE_LOG_ENTRY) {
			return <CardItemSimpleControls
				cardId={`${Math.random()}${document}`}
				actionEdit={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionDelete={authContext.hasPermission(Permission.DOCUMENT_DELETE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				onClick={(actionPayload) => onCardItemControlClick(actionPayload, documentEntry)}
			/>;
		} if (documentEntry.Scope === DocumentModelScope.DEVICE) {
			return <CardItemControls
				cardId={`${Math.random()}${document}`}
				actionEdit={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionArchive={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionDelete={authContext.hasPermission(Permission.DOCUMENT_DELETE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				onClick={(actionPayload) => onCardItemControlClick(actionPayload, documentEntry)}
			/>;
		}  if (documentEntry.Scope === DocumentModelScope.RECORD) {
			return <CardItemControls
				cardId={`${Math.random()}${document}`}
				actionDelete={authContext.hasPermission(Permission.DOCUMENT_DELETE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				onClick={(actionPayload) => onCardItemControlClick(actionPayload, documentEntry)}
			/>;
		} if (documentEntry.Scope === DocumentModelScope.DOCUMENT_FOLDER) {
			return <CardItemControls
				cardId={`${Math.random()}${document}`}
				actionEdit={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionArchive={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionDelete={authContext.hasPermission(Permission.DOCUMENT_DELETE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				actionMove={authContext.hasPermission(Permission.DOCUMENT_UPDATE) ? CardItemControlState.ENABLED : CardItemControlState.DISABLED}
				onClick={(actionPayload) => onCardItemControlClick(actionPayload, documentEntry)}
			/>;
		}

		return null;
	};

	return (
		<>
			<CardDocument
				key={Guid.generate()}
				cardId={`${Math.random()}${document}`}
				button={{
					icon: IconIdentifier.DOWNLOAD,
					disabled: !networkContext.online,
					onClick: () => onCardItemControlClick({
						type: CardItemActionType.DOWNLOAD,
						cardId: ''
					}, documentEntry)
				}}
			>
				<CardItemHeader
					title={documentEntry.Filename}
					illustration={documentEntry.Scope !== DocumentModelScope.MAINTENANCE_LOG_ENTRY ? IllustrationIdentifier.DOCUMENT_PLACE : null}
					archived={documentEntry.State === DocumentModelState.ARCHIVED}
				/>

				{!isReportView ? controls() : null}

				<CardItemState date={formatedDocumentDate}>
					<CardItemFileSize label={formatedFileExtension} />
				</CardItemState>
			</CardDocument>
		</>
	);
};
